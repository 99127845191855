export function cleanObject<T extends object>(obj: T): Partial<T> {
    return Object.keys(obj).reduce((acc, key) => {
        let value = obj[key as keyof T];

        // Преобразование строковых чисел в действительные числа
        if (typeof value === 'string' && !isNaN(Number(value))) {
            // @ts-ignore
            value = Number(value);
        }

        if (Array.isArray(value) && (value as any[]).length === 0) {
            return acc;
        } else if (value === null) {
            return acc;
        } else {
            acc[key as keyof T] = value;
            return acc;
        }
    }, {} as Partial<T>);
}
