import {browserTracingIntegration, init, replayIntegration} from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const {public: {sentry}} = useRuntimeConfig()

    if (!sentry.dsn || window.location.hostname === 'localhost') {
        return
    }

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)
    const initSentry = () => {
        init({
            app: nuxtApp.vueApp,
            dsn: sentry.dsn,
            environment: sentry.environment,
            integrations: isMobile
                ? [] // Без интеграций на мобильных для минимизации влияния
                : [browserTracingIntegration({router}), replayIntegration()],
            // Configure this whole part as you need it!
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['https://csmarketcap.com', 'https://dev.csmarketcap.com'],
            tracesSampleRate: isMobile ? 0.01 : 0.2, // Минимальное отслеживание на мобильных
            replaysSessionSampleRate: isMobile ? 0.0 : 1.0, // Отключить записи сессий на мобильных
            replaysOnErrorSampleRate: isMobile ? 0.05 : 1.0, // Только критические ошибки на мобильных
            maxBreadcrumbs: isMobile ? 20 : 100,
            beforeSend(event) {
                if (isMobile) {
                    // Отправляем только критические ошибки на мобильных
                    if (event.level !== 'fatal') {
                        return null;
                    }
                }
                // Игнорируем ошибки, связанные с abortController
                if (event.exception && event.exception.values) {
                    for (const exception of event.exception.values) {
                        if (exception.type === 'AbortError') {
                            return null;
                        }
                    }
                }
                return event;
            },
        })
    }

    if (isMobile) {
        // Откладываем инициализацию на мобильных до полной загрузки страницы
        window.addEventListener('load', () => {
            initSentry();
        });
    } else {
        initSentry();
    }
})
