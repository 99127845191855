<template>
  <NuxtLink :to="localePath('/')" class="logo">
    <img alt="logo" height="30" src="/img/logo.svg" width="100" />
  </NuxtLink>
</template>

<script lang="ts" setup>
import {useLocalePath} from "#i18n";

const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.logo {
  margin-right: 2rem;
  flex-shrink: 0;

  img {
    height: 2.2rem;
  }
}
</style>
