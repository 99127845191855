export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AdvancedSearchBody = {
  __typename?: 'AdvancedSearchBody';
  collection?: Maybe<Scalars['String']['output']>;
  colors?: Maybe<Array<Color>>;
  crates?: Maybe<Array<Maybe<CrateItem>>>;
  exterior?: Maybe<Scalars['String']['output']>;
  has_souvenir: Scalars['Boolean']['output'];
  has_stattrak: Scalars['Boolean']['output'];
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  localed_rarity?: Maybe<Scalars['String']['output']>;
  localed_skin?: Maybe<Scalars['String']['output']>;
  market_hash_name: Scalars['String']['output'];
  markets?: Maybe<Scalars['Int']['output']>;
  max_price?: Maybe<Scalars['Int']['output']>;
  min_price?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
};

export type AdvancedSearchInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collection?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  colorGroup?: InputMaybe<ColorGroup>;
  exterior?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Extra>;
  groupBy?: InputMaybe<GroupBy>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  orderBy: OrderBy;
  phase?: InputMaybe<Scalars['String']['input']>;
  rarity?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  stickerEffect?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  tournamentEvent?: InputMaybe<Scalars['String']['input']>;
  weapon?: InputMaybe<Scalars['String']['input']>;
};

export type AdvancedSearchResult = {
  __typename?: 'AdvancedSearchResult';
  max_pages: Scalars['Int']['output'];
  result: Array<Maybe<AdvancedSearchBody>>;
};

export type AdvancedSearchType = {
  __typename?: 'AdvancedSearchType';
  category?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  colorGroup?: Maybe<ColorGroup>;
  exterior?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Extra>;
  groupBy?: Maybe<GroupBy>;
  maxPrice?: Maybe<Scalars['Int']['output']>;
  minPrice?: Maybe<Scalars['Int']['output']>;
  orderBy: OrderBy;
  phase?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  searchText?: Maybe<Scalars['String']['output']>;
  stickerEffect?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  tournamentEvent?: Maybe<Scalars['String']['output']>;
  weapon?: Maybe<Scalars['String']['output']>;
};

export type AggregatedMinPriceItem = {
  __typename?: 'AggregatedMinPriceItem';
  market_hash_name: Scalars['String']['output'];
  prices: Array<Maybe<MinPrice>>;
};

export type AvailableMarkets = {
  __typename?: 'AvailableMarkets';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
};

export type BlogCategory = {
  __typename?: 'BlogCategory';
  color: Scalars['String']['output'];
  created_at: Scalars['Date']['output'];
  data: Array<BlogCategoryDataType>;
  lang: Scalars['String']['output'];
  link: Scalars['String']['output'];
  posts_count?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['Date']['output'];
};

export type BlogCategoryDataInput = {
  lang: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type BlogCategoryDataType = {
  __typename?: 'BlogCategoryDataType';
  lang: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BlogPost = {
  __typename?: 'BlogPost';
  author: UserInfo;
  category: BlogCategory;
  content: Scalars['String']['output'];
  created_at: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  hidden: Scalars['Boolean']['output'];
  img: Scalars['String']['output'];
  is_main: Scalars['Boolean']['output'];
  lang: Scalars['String']['output'];
  langs: Array<Maybe<Scalars['String']['output']>>;
  last_edited_by: UserInfo;
  liked?: Maybe<Scalars['Date']['output']>;
  likes_count: Scalars['String']['output'];
  link: Scalars['String']['output'];
  published_at: Scalars['Date']['output'];
  read_time: Scalars['Int']['output'];
  tags: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['Date']['output'];
  viewed?: Maybe<Scalars['Date']['output']>;
  views: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  category: Scalars['String']['output'];
  items: Array<Maybe<Item>>;
};

export type Color = {
  __typename?: 'Color';
  hex: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type ColorClear = {
  __typename?: 'ColorClear';
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ColorGroup {
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type ColorItemCount = {
  __typename?: 'ColorItemCount';
  count: Scalars['Int']['output'];
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CompareItem = {
  __typename?: 'CompareItem';
  exterior?: Maybe<CompareItemExterior>;
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  liked?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  localed_rarity?: Maybe<Scalars['String']['output']>;
  localed_skin?: Maybe<Scalars['String']['output']>;
  market_hash_name: Scalars['String']['output'];
  max_price: ComparisonPriceInfo;
  min_price: ComparisonPriceInfo;
  phase?: Maybe<Scalars['String']['output']>;
  profit: ComparisonProfit;
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  sales: Scalars['Int']['output'];
  steam_price?: Maybe<ComparisonPriceInfo>;
  weapon?: Maybe<Scalars['String']['output']>;
};

export type CompareItemExterior = {
  __typename?: 'CompareItemExterior';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompareOutput = {
  __typename?: 'CompareOutput';
  items: Array<Maybe<CompareItem>>;
  settings: ComparePageSettings;
};

export type ComparePageSettings = {
  __typename?: 'ComparePageSettings';
  hasNextPage: Scalars['Boolean']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  page: Scalars['Int']['output'];
  sort?: Maybe<ComparisonSort>;
  totalCount: Scalars['Int']['output'];
};

export type ComparisonFiltersCategoryData = {
  __typename?: 'ComparisonFiltersCategoryData';
  id: SkinCategory;
  title: Scalars['String']['output'];
};

export type ComparisonFiltersData = {
  __typename?: 'ComparisonFiltersData';
  categories: Array<Maybe<ComparisonFiltersCategoryData>>;
  collections: Array<Maybe<Scalars['String']['output']>>;
  exteriors: Array<Maybe<Scalars['String']['output']>>;
  markets: Array<Maybe<AvailableMarkets>>;
  rarityGroups: Array<Maybe<ComparisonFiltersRarityGroupData>>;
  types: Array<Maybe<Category>>;
};

export type ComparisonFiltersInput = {
  categories?: InputMaybe<Array<InputMaybe<SkinCategory>>>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exteriors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  liked?: InputMaybe<Scalars['Boolean']['input']>;
  markets_from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  markets_to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offers?: InputMaybe<Scalars['Int']['input']>;
  price_from?: InputMaybe<Scalars['Int']['input']>;
  price_to?: InputMaybe<Scalars['Int']['input']>;
  profit_percentage_from?: InputMaybe<Scalars['Float']['input']>;
  profit_percentage_to?: InputMaybe<Scalars['Int']['input']>;
  profit_value_from?: InputMaybe<Scalars['Int']['input']>;
  profit_value_to?: InputMaybe<Scalars['Int']['input']>;
  rarities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sales?: InputMaybe<Scalars['Int']['input']>;
  sales_period: SalesPeriod;
  search?: InputMaybe<Scalars['String']['input']>;
  steam_price_from?: InputMaybe<Scalars['Int']['input']>;
  steam_price_to?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComparisonFiltersRarityData = {
  __typename?: 'ComparisonFiltersRarityData';
  rarity: Scalars['String']['output'];
  rarity_color: Scalars['String']['output'];
};

export type ComparisonFiltersRarityGroupData = {
  __typename?: 'ComparisonFiltersRarityGroupData';
  rarities: Array<Maybe<ComparisonFiltersRarityData>>;
  title: Scalars['String']['output'];
};

export type ComparisonInput = {
  filter: ComparisonFiltersInput;
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  sort: ComparisonSort;
};

export type ComparisonMarket = {
  __typename?: 'ComparisonMarket';
  market: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type ComparisonMarketInfo = {
  __typename?: 'ComparisonMarketInfo';
  average_price: Scalars['Int']['output'];
  max_price: Scalars['Int']['output'];
  min_price: Scalars['Int']['output'];
  offers: Array<Maybe<ComparisonPriceInfo>>;
  sales: Scalars['Int']['output'];
};

export type ComparisonMarketsInput = {
  link: Scalars['String']['input'];
  markets_from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  markets_to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offers?: InputMaybe<Scalars['Int']['input']>;
  price_from?: InputMaybe<Scalars['Int']['input']>;
  price_to?: InputMaybe<Scalars['Int']['input']>;
  sales_period: SalesPeriod;
};

export type ComparisonPriceInfo = {
  __typename?: 'ComparisonPriceInfo';
  market: ComparisonPriceMarket;
  price: Scalars['Int']['output'];
};

export type ComparisonPriceMarket = {
  __typename?: 'ComparisonPriceMarket';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
};

export type ComparisonProfit = {
  __typename?: 'ComparisonProfit';
  percentage: Scalars['Float']['output'];
  value: Scalars['Int']['output'];
};

export enum ComparisonSort {
  MaxPriceAsc = 'MAX_PRICE_ASC',
  MaxPriceDesc = 'MAX_PRICE_DESC',
  MinPriceAsc = 'MIN_PRICE_ASC',
  MinPriceDesc = 'MIN_PRICE_DESC',
  ProfitPercentageAsc = 'PROFIT_PERCENTAGE_ASC',
  ProfitPercentageDesc = 'PROFIT_PERCENTAGE_DESC',
  ProfitValueAsc = 'PROFIT_VALUE_ASC',
  ProfitValueDesc = 'PROFIT_VALUE_DESC',
  SalesAsc = 'SALES_ASC',
  SalesDesc = 'SALES_DESC',
  SteamPriceAsc = 'STEAM_PRICE_ASC',
  SteamPriceDesc = 'STEAM_PRICE_DESC'
}

export type ContainerItem = {
  __typename?: 'ContainerItem';
  link: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type CrateItem = {
  __typename?: 'CrateItem';
  link: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type CratesItem = {
  __typename?: 'CratesItem';
  link: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type CreateBlogCategoryInput = {
  color: Scalars['String']['input'];
  data: Array<BlogCategoryDataInput>;
};

export type CreateBlogPostInput = {
  category: Scalars['String']['input'];
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  hidden: Scalars['Boolean']['input'];
  img: Scalars['String']['input'];
  is_main: Scalars['Boolean']['input'];
  published_at?: InputMaybe<Scalars['Date']['input']>;
  tags: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateDynamicPageInput = {
  advanced_search_parameters: AdvancedSearchInput;
  image_url?: InputMaybe<Scalars['String']['input']>;
  locals: Array<DynamicPageLocal>;
  slug: Scalars['String']['input'];
};

export type DailyChart = {
  __typename?: 'DailyChart';
  avg_price: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  max_price: Scalars['Int']['output'];
  min_price: Scalars['Int']['output'];
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
};

export type Day = {
  __typename?: 'Day';
  date: Scalars['String']['output'];
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
};

export type DeleteBlogCategoryInput = {
  link: Scalars['String']['input'];
};

export type DeleteBlogPostInput = {
  lang: Scalars['String']['input'];
  link: Scalars['String']['input'];
};

export type Description = {
  __typename?: 'Description';
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DynamicPageBaseInfo = {
  __typename?: 'DynamicPageBaseInfo';
  slug: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type DynamicPageFullInfo = {
  __typename?: 'DynamicPageFullInfo';
  advancedSearchParameters: AdvancedSearchType;
  image_url?: Maybe<Scalars['String']['output']>;
  locals?: Maybe<Array<DynamicPageLocalType>>;
  slug: Scalars['String']['output'];
};

export type DynamicPageFullInfoLocal = {
  __typename?: 'DynamicPageFullInfoLocal';
  seo_tags: DynamicPageLocalSeoData;
  user_description: Scalars['String']['output'];
  user_text: Scalars['String']['output'];
};

export type DynamicPageInfo = {
  __typename?: 'DynamicPageInfo';
  all?: Maybe<Array<Maybe<DynamicPageBaseInfo>>>;
  data: AdvancedSearchResult;
  info?: Maybe<DynamicPageFullInfo>;
};


export type DynamicPageInfoAllArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type DynamicPageInfoDataArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
};


export type DynamicPageInfoInfoArgs = {
  slug: Scalars['String']['input'];
};

export type DynamicPageLocal = {
  local: Scalars['String']['input'];
  seo_tags: DynamicPageLocalSeoDataInput;
  user_description: Scalars['String']['input'];
  user_text: Scalars['String']['input'];
};

export type DynamicPageLocalSeoData = {
  __typename?: 'DynamicPageLocalSeoData';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DynamicPageLocalSeoDataInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type DynamicPageLocalSeoDataType = {
  __typename?: 'DynamicPageLocalSeoDataType';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DynamicPageLocalType = {
  __typename?: 'DynamicPageLocalType';
  local: Scalars['String']['output'];
  seo_tags: DynamicPageLocalSeoDataType;
  user_description: Scalars['String']['output'];
  user_text: Scalars['String']['output'];
};

export type Exterior = {
  __typename?: 'Exterior';
  exterior?: Maybe<Scalars['String']['output']>;
  extra_link?: Maybe<Scalars['String']['output']>;
  extra_name?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum Extra {
  HasSouvenir = 'HAS_SOUVENIR',
  HasStattrak = 'HAS_STATTRAK',
  NoSouvenir = 'NO_SOUVENIR',
  NoStattrak = 'NO_STATTRAK'
}

export enum ExtraWeaponPage {
  Normal = 'NORMAL',
  Souvenir = 'SOUVENIR',
  Stattrak = 'STATTRAK'
}

export type FamilySimilarSkins = {
  __typename?: 'FamilySimilarSkins';
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
};

export type GetBlogCategoriesInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type GetBlogCategoryInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
};

export type GetBlogPostInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
};

export type GetBlogPostsOutput = {
  __typename?: 'GetBlogPostsOutput';
  data: Array<Maybe<BlogPost>>;
  max_pages: Scalars['Int']['output'];
};

export type GetBlogPostsPageParamsInput = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type GetBlogPostsSearchParamsInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  is_main?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  liked?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetItemRatingResult = {
  __typename?: 'GetItemRatingResult';
  rating: Scalars['Float']['output'];
  votes: Scalars['Int']['output'];
};

export type GetMainBlogPostInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export enum GroupBy {
  ItemName = 'ITEM_NAME',
  ItemNameStattrackSouvenier = 'ITEM_NAME_STATTRACK_SOUVENIER',
  Type = 'TYPE'
}

export type Item = {
  __typename?: 'Item';
  full_name: Scalars['String']['output'];
  weapon: Scalars['String']['output'];
};

export type ItemColorGroupResult = {
  __typename?: 'ItemColorGroupResult';
  containedColors: Array<ColorClear>;
  group: ColorGroup;
};

export type ItemCountByColor = {
  __typename?: 'ItemCountByColor';
  count: Scalars['Int']['output'];
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ItemPageInfo = {
  __typename?: 'ItemPageInfo';
  available_markets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  category: Scalars['String']['output'];
  chart?: Maybe<Array<Maybe<WeeklyChart>>>;
  collection?: Maybe<Scalars['String']['output']>;
  colorItemCount: Array<Maybe<ColorItemCount>>;
  colors?: Maybe<Array<Maybe<Color>>>;
  contains?: Maybe<Array<Maybe<ContainerItem>>>;
  crates?: Maybe<Array<Maybe<CratesItem>>>;
  current_price?: Maybe<Scalars['Int']['output']>;
  descriptions?: Maybe<Array<Maybe<Description>>>;
  designer_link?: Maybe<Scalars['String']['output']>;
  designer_name?: Maybe<Scalars['String']['output']>;
  exterior?: Maybe<Scalars['String']['output']>;
  exteriors?: Maybe<Array<Maybe<Exterior>>>;
  family_skins?: Maybe<Array<Maybe<FamilySimilarSkins>>>;
  id: Scalars['Int']['output'];
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  locals: Locals;
  market_hash_name: Scalars['String']['output'];
  markets_info?: Maybe<Array<Maybe<MarketInfo>>>;
  markets_offers?: Maybe<Array<Maybe<MarketOffer>>>;
  max_float?: Maybe<Scalars['String']['output']>;
  min_float?: Maybe<Scalars['String']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  phases?: Maybe<Array<Maybe<PhaseItem>>>;
  popularity?: Maybe<Scalars['String']['output']>;
  price_statistics?: Maybe<PriceStatistics>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  seven_days?: Maybe<Array<Maybe<DailyChart>>>;
  similar_skins?: Maybe<Array<Maybe<FamilySimilarSkins>>>;
  skin?: Maybe<Scalars['String']['output']>;
  sticker_effect?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  tournament_event?: Maybe<Scalars['String']['output']>;
  update_link?: Maybe<Scalars['String']['output']>;
  update_text?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Array<Maybe<Volume>>>;
  weapon?: Maybe<Scalars['String']['output']>;
  workshop_link?: Maybe<Scalars['String']['output']>;
};

export type ItemStatsByPeriod = {
  __typename?: 'ItemStatsByPeriod';
  daily?: Maybe<Array<Maybe<DailyChart>>>;
  weekly?: Maybe<Array<Maybe<WeeklyChart>>>;
};

export type ItemWeaponPageFiltersResult = {
  __typename?: 'ItemWeaponPageFiltersResult';
  normal_count?: Maybe<Scalars['Int']['output']>;
  rarities_count?: Maybe<Array<Maybe<RaritiesCount>>>;
  souvenir_count?: Maybe<Scalars['Int']['output']>;
  stattrak_count?: Maybe<Scalars['Int']['output']>;
  teams?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ItemsByField = {
  __typename?: 'ItemsByField';
  cases?: Maybe<Array<Maybe<CrateItem>>>;
  category: Scalars['String']['output'];
  collection?: Maybe<Scalars['String']['output']>;
  colors?: Maybe<Array<Maybe<Color>>>;
  extra_range?: Maybe<PriceRange>;
  has_souvenir: Scalars['Boolean']['output'];
  has_stattrak: Scalars['Boolean']['output'];
  is_music_kit_box: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  markets_with_offers?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  price_range?: Maybe<PriceRange>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Float']['output'];
  skin?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  votes: Scalars['Int']['output'];
  weapon?: Maybe<Scalars['String']['output']>;
};

export type ItemsByFieldResult = {
  __typename?: 'ItemsByFieldResult';
  max_pages: Scalars['Int']['output'];
  result: Array<Maybe<ItemsByField>>;
};

export type ItemsContainerPageResult = {
  __typename?: 'ItemsContainerPageResult';
  description?: Maybe<Scalars['String']['output']>;
  gloves: Array<Maybe<Scalars['String']['output']>>;
  knives: Array<Maybe<Scalars['String']['output']>>;
  localed_name: Scalars['String']['output'];
  normal: Array<Maybe<Scalars['String']['output']>>;
  result: Array<Maybe<ItemsByField>>;
};

export type ItemsPriceDiffs = {
  __typename?: 'ItemsPriceDiffs';
  exterior?: Maybe<Scalars['String']['output']>;
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  price_difference: Scalars['Float']['output'];
  rarity_color?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
};

export type LikeBlogPostInput = {
  link: Scalars['String']['input'];
};

export type Locals = {
  __typename?: 'Locals';
  description?: Maybe<Scalars['String']['output']>;
  localed_collection?: Maybe<Scalars['String']['output']>;
  localed_name: Scalars['String']['output'];
  pattern?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
};

export type MakeVoteInput = {
  marketHashName: Scalars['String']['input'];
  vote: Scalars['Int']['input'];
};

export type MakeVoteResult = {
  __typename?: 'MakeVoteResult';
  market_hash_name: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  votes: Scalars['Int']['output'];
};

export type MarketInfo = {
  __typename?: 'MarketInfo';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  trust_pilot_link?: Maybe<Scalars['String']['output']>;
  votes: Scalars['Int']['output'];
};

export type MarketNames = {
  __typename?: 'MarketNames';
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
};

export type MarketOffer = {
  __typename?: 'MarketOffer';
  last_update: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  offers: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  price_locked?: Maybe<Scalars['Int']['output']>;
};

export type MarketStat = {
  __typename?: 'MarketStat';
  days: Array<Maybe<Day>>;
  market_full_name: Scalars['String']['output'];
  market_logo_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  seven_days_sales_value?: Maybe<Scalars['String']['output']>;
  seven_days_value_diff?: Maybe<Scalars['String']['output']>;
  today_count_diff?: Maybe<Scalars['String']['output']>;
  today_sales_count: Scalars['String']['output'];
  today_sales_value: Scalars['String']['output'];
  today_value_diff?: Maybe<Scalars['String']['output']>;
};

export type MarketStatForPeriod = {
  __typename?: 'MarketStatForPeriod';
  all_sales: Scalars['Int']['output'];
  avg_sales: Scalars['Int']['output'];
  days?: Maybe<Array<Maybe<Day>>>;
  max_sales: Scalars['Int']['output'];
  min_sales: Scalars['Int']['output'];
  weeks?: Maybe<Array<Maybe<Week>>>;
};

export type MinPrice = {
  __typename?: 'MinPrice';
  market_name: Scalars['String']['output'];
  offers: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createBlogCategory: BlogCategory;
  createBlogPost: BlogPost;
  createDynamicPage: Scalars['Boolean']['output'];
  deleteBlogCategory: Scalars['String']['output'];
  deleteBlogPost: Scalars['String']['output'];
  deleteDynamicPage: Scalars['Boolean']['output'];
  deleteFile: Scalars['Boolean']['output'];
  likeBlogPost: Scalars['String']['output'];
  likeItem?: Maybe<Scalars['Date']['output']>;
  makeVote: MakeVoteResult;
  updateBlogCategory: BlogCategory;
  updateBlogPost: BlogPost;
  updateDynamicPage: Scalars['Boolean']['output'];
  userInfo: UserInfo;
  viewBlogPost: Scalars['String']['output'];
};


export type MutationCreateBlogCategoryArgs = {
  input: CreateBlogCategoryInput;
};


export type MutationCreateBlogPostArgs = {
  input: CreateBlogPostInput;
};


export type MutationCreateDynamicPageArgs = {
  input: CreateDynamicPageInput;
};


export type MutationDeleteBlogCategoryArgs = {
  input: DeleteBlogCategoryInput;
};


export type MutationDeleteBlogPostArgs = {
  input: DeleteBlogPostInput;
};


export type MutationDeleteDynamicPageArgs = {
  slug: Scalars['String']['input'];
};


export type MutationDeleteFileArgs = {
  url: Scalars['String']['input'];
};


export type MutationLikeBlogPostArgs = {
  input: LikeBlogPostInput;
};


export type MutationLikeItemArgs = {
  itemLink: Scalars['String']['input'];
};


export type MutationMakeVoteArgs = {
  input: MakeVoteInput;
};


export type MutationUpdateBlogCategoryArgs = {
  input: UpdateBlogCategoryInput;
};


export type MutationUpdateBlogPostArgs = {
  input: UpdateBlogPostInput;
};


export type MutationUpdateDynamicPageArgs = {
  input: UpdateDynamicPageInput;
};


export type MutationUserInfoArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  steam_api_key?: InputMaybe<Scalars['String']['input']>;
  trade_link?: InputMaybe<Scalars['String']['input']>;
};


export type MutationViewBlogPostArgs = {
  input: ViewBlogPostInput;
};

export enum OrderBy {
  Alphabetically = 'ALPHABETICALLY',
  ColorMatch = 'COLOR_MATCH',
  HighestPrice = 'HIGHEST_PRICE',
  LowestPrice = 'LOWEST_PRICE',
  MostOffers = 'MOST_OFFERS',
  MostVolume = 'MOST_VOLUME',
  Popularity = 'POPULARITY',
  Rarity = 'RARITY'
}

export enum Period {
  All = 'ALL',
  OneMonth = 'ONE_MONTH',
  SevenDays = 'SEVEN_DAYS',
  ThreeMonths = 'THREE_MONTHS'
}

export type PhaseItem = {
  __typename?: 'PhaseItem';
  link_name: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  phase: Scalars['String']['output'];
};

export type PriceRange = {
  __typename?: 'PriceRange';
  highest_link?: Maybe<Scalars['String']['output']>;
  highest_price?: Maybe<Scalars['Int']['output']>;
  lowest_link?: Maybe<Scalars['String']['output']>;
  lowest_price?: Maybe<Scalars['Int']['output']>;
  market_hash_name_highest?: Maybe<Scalars['String']['output']>;
  market_hash_name_lowest?: Maybe<Scalars['String']['output']>;
};

export type PriceStatistics = {
  __typename?: 'PriceStatistics';
  all_time_high?: Maybe<Scalars['Int']['output']>;
  all_time_low?: Maybe<Scalars['Int']['output']>;
  ninety_day_high?: Maybe<Scalars['Int']['output']>;
  ninety_day_low?: Maybe<Scalars['Int']['output']>;
  seven_day_average?: Maybe<Scalars['Int']['output']>;
  seven_day_chart: Array<Maybe<DailyChart>>;
  seven_day_high?: Maybe<Scalars['Int']['output']>;
  seven_day_low?: Maybe<Scalars['Int']['output']>;
  thirty_day_average?: Maybe<Scalars['Int']['output']>;
  thirty_day_high?: Maybe<Scalars['Int']['output']>;
  thirty_day_low?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  advancedSearch: AdvancedSearchResult;
  aggregatedMinPrices: Array<Maybe<AggregatedMinPriceItem>>;
  availableMarkets: Array<Maybe<MarketNames>>;
  compareMarketsInfo: ComparisonMarketInfo;
  comparePageFilters: ComparisonFiltersData;
  comparePageInfo: CompareOutput;
  dynamicPage?: Maybe<DynamicPageInfo>;
  getBlogCategories: Array<Maybe<BlogCategory>>;
  getBlogCategory: BlogCategory;
  getBlogPost: BlogPost;
  getBlogPosts?: Maybe<GetBlogPostsOutput>;
  getCategories: Array<Maybe<Category>>;
  getItemRating: GetItemRatingResult;
  getMainBlogPost: BlogPost;
  getPriceRecommendation: RecommendationPrice;
  getPriceRecommendations: Array<Maybe<RecommendationPrice>>;
  getSteamAnalytics: Array<Maybe<SteamAnalytics>>;
  getSteamAnalyticsForItem: SteamAnalytics;
  getUserVote?: Maybe<Scalars['Int']['output']>;
  itemColorGroup: Array<ItemColorGroupResult>;
  itemPageInfo: ItemPageInfo;
  itemStatsByPeriod: ItemStatsByPeriod;
  itemsContainerPage?: Maybe<ItemsContainerPageResult>;
  itemsCountByColor?: Maybe<Array<Maybe<ItemCountByColor>>>;
  itemsCountByColors?: Maybe<Array<Maybe<ItemCountByColor>>>;
  itemsPriceDiffs: Array<Maybe<ItemsPriceDiffs>>;
  itemsWeaponPage?: Maybe<ItemsByFieldResult>;
  itemsWeaponPageFilters?: Maybe<ItemWeaponPageFiltersResult>;
  marketStatForPeriod: MarketStatForPeriod;
  marketsMainStats: Array<Maybe<MarketStat>>;
  searchParams: SearchParams;
  simpleSearch?: Maybe<Array<Maybe<SimpleSearch>>>;
  userInfo: UserInfo;
};


export type QueryAdvancedSearchArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  searchParams?: InputMaybe<AdvancedSearchInput>;
};


export type QueryAggregatedMinPricesArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  markets: Array<Scalars['String']['input']>;
};


export type QueryCompareMarketsInfoArgs = {
  input: ComparisonMarketsInput;
};


export type QueryComparePageInfoArgs = {
  input: ComparisonInput;
};


export type QueryGetBlogCategoriesArgs = {
  input: GetBlogCategoriesInput;
};


export type QueryGetBlogCategoryArgs = {
  input: GetBlogCategoryInput;
};


export type QueryGetBlogPostArgs = {
  input: GetBlogPostInput;
};


export type QueryGetBlogPostsArgs = {
  pageParams: GetBlogPostsPageParamsInput;
  searchParams: GetBlogPostsSearchParamsInput;
};


export type QueryGetItemRatingArgs = {
  itemID: Scalars['Int']['input'];
};


export type QueryGetMainBlogPostArgs = {
  input?: InputMaybe<GetMainBlogPostInput>;
};


export type QueryGetPriceRecommendationArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  market_hash_name: Scalars['String']['input'];
  type?: InputMaybe<RecommendationType>;
};


export type QueryGetPriceRecommendationsArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<RecommendationType>;
};


export type QueryGetSteamAnalyticsForItemArgs = {
  market_hash_name: Scalars['String']['input'];
};


export type QueryGetUserVoteArgs = {
  marketHashName: Scalars['String']['input'];
};


export type QueryItemPageInfoArgs = {
  itemLink: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryItemStatsByPeriodArgs = {
  itemName: Scalars['String']['input'];
  marketName: Scalars['String']['input'];
  period: Period;
};


export type QueryItemsContainerPageArgs = {
  containerLinkName: Scalars['String']['input'];
  isRareSpecial: Scalars['Boolean']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryItemsCountByColorArgs = {
  colors: Array<Scalars['String']['input']>;
};


export type QueryItemsPriceDiffsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryItemsWeaponPageArgs = {
  input: WeaponPageInput;
};


export type QueryItemsWeaponPageFiltersArgs = {
  category: Scalars['String']['input'];
};


export type QueryMarketStatForPeriodArgs = {
  marketName: Scalars['String']['input'];
  period: Period;
};


export type QuerySimpleSearchArgs = {
  searchText: Scalars['String']['input'];
};

export type RaritiesCount = {
  __typename?: 'RaritiesCount';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RecommendationPrice = {
  __typename?: 'RecommendationPrice';
  market_hash_name: Scalars['String']['output'];
  price_change_percentage?: Maybe<Scalars['Float']['output']>;
  price_difference?: Maybe<Scalars['Float']['output']>;
  suggested_price: Scalars['Float']['output'];
  total_sales: Scalars['Int']['output'];
  unstable_reasons: Array<Maybe<Scalars['String']['output']>>;
};

export enum RecommendationType {
  General = 'general',
  P2p = 'p2p'
}

export enum SalesPeriod {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type SearchParamColor = {
  __typename?: 'SearchParamColor';
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SearchParams = {
  __typename?: 'SearchParams';
  categories: Array<Maybe<Scalars['String']['output']>>;
  collections: Array<Maybe<Scalars['String']['output']>>;
  color_group: Array<ColorGroup>;
  colors: Array<SearchParamColor>;
  exteriors: Array<Maybe<Scalars['String']['output']>>;
  extra: Array<Maybe<Extra>>;
  group_by?: Maybe<Array<Maybe<GroupBy>>>;
  order_by: Array<Maybe<OrderBy>>;
  phases: Array<Maybe<Scalars['String']['output']>>;
  rarities: Array<Maybe<Scalars['String']['output']>>;
  sticker_effects: Array<Maybe<Scalars['String']['output']>>;
  teams: Array<Maybe<Scalars['String']['output']>>;
  tournament_events: Array<Maybe<Scalars['String']['output']>>;
  weapons: Array<Maybe<Scalars['String']['output']>>;
};

export type SimpleSearch = {
  __typename?: 'SimpleSearch';
  link?: Maybe<Scalars['String']['output']>;
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
};

export enum SkinCategory {
  Normal = 'NORMAL',
  Rare = 'RARE',
  RareStattrak = 'RARE_STATTRAK',
  Souvenir = 'SOUVENIR',
  Stattrak = 'STATTRAK'
}

export type SteamAnalytics = {
  __typename?: 'SteamAnalytics';
  buyOrders?: Maybe<SteamBuyOrdersItemData>;
  listings?: Maybe<SteamListingsItemData>;
  market_hash_name?: Maybe<Scalars['String']['output']>;
  sales?: Maybe<SteamSalesItemData>;
};

export type SteamBuyOrdersItemData = {
  __typename?: 'SteamBuyOrdersItemData';
  average_buy_price_90d?: Maybe<Scalars['Float']['output']>;
  buy_orders_last_7d?: Maybe<Scalars['Int']['output']>;
  buy_orders_last_24h?: Maybe<Scalars['Int']['output']>;
  latest_buy_order_price?: Maybe<Scalars['Float']['output']>;
  max_buy_order_price?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_7d?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_24h?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_30d?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_90d?: Maybe<Scalars['Int']['output']>;
  median_buy_price_90d?: Maybe<Scalars['Int']['output']>;
  min_buy_order_price?: Maybe<Scalars['Int']['output']>;
};

export type SteamListingsItemData = {
  __typename?: 'SteamListingsItemData';
  average_sell_price_90d?: Maybe<Scalars['Float']['output']>;
  latest_sell_order_price?: Maybe<Scalars['Float']['output']>;
  max_sell_order_price?: Maybe<Scalars['Int']['output']>;
  median_sell_price_90d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_7d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_24h?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_30d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_90d?: Maybe<Scalars['Int']['output']>;
  sell_orders_last_7d?: Maybe<Scalars['Int']['output']>;
  sell_orders_last_24h?: Maybe<Scalars['Int']['output']>;
};

export type SteamSalesItemData = {
  __typename?: 'SteamSalesItemData';
  average_price_90d?: Maybe<Scalars['Float']['output']>;
  avg_daily_volume?: Maybe<Scalars['Float']['output']>;
  last_7d?: Maybe<Scalars['Int']['output']>;
  last_24h?: Maybe<Scalars['Int']['output']>;
  last_30d?: Maybe<Scalars['Int']['output']>;
  last_90d?: Maybe<Scalars['Int']['output']>;
  latest_median_price_sold?: Maybe<Scalars['Float']['output']>;
  median_price_90d?: Maybe<Scalars['Float']['output']>;
};

export enum Teams {
  Both = 'BOTH',
  Ct = 'CT',
  T = 'T'
}

export type UpdateBlogCategoryInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Array<BlogCategoryDataInput>>;
  link: Scalars['String']['input'];
};

export type UpdateBlogPostInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  img?: InputMaybe<Scalars['String']['input']>;
  is_main?: InputMaybe<Scalars['Boolean']['input']>;
  lang: Scalars['String']['input'];
  link: Scalars['String']['input'];
  published_at?: InputMaybe<Scalars['Date']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDynamicPageInput = {
  advanced_search_parameters?: InputMaybe<AdvancedSearchInput>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  locals?: InputMaybe<Array<DynamicPageLocal>>;
  slug: Scalars['String']['input'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  avatar_url: Scalars['String']['output'];
  balance: Scalars['Int']['output'];
  coutry_code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  is_admin: Scalars['Boolean']['output'];
  persona_name: Scalars['String']['output'];
  real_name?: Maybe<Scalars['String']['output']>;
  steam_api_key?: Maybe<Scalars['String']['output']>;
  steamid64: Scalars['String']['output'];
  trade_link?: Maybe<Scalars['String']['output']>;
};

export type ViewBlogPostInput = {
  link: Scalars['String']['input'];
};

export type Volume = {
  __typename?: 'Volume';
  extra_full_name?: Maybe<Scalars['String']['output']>;
  extra_last_update?: Maybe<Scalars['String']['output']>;
  extra_link?: Maybe<Scalars['String']['output']>;
  extra_market?: Maybe<Scalars['String']['output']>;
  extra_offers?: Maybe<Scalars['Int']['output']>;
  extra_price?: Maybe<Scalars['Int']['output']>;
  full_name: Scalars['String']['output'];
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  market?: Maybe<Scalars['String']['output']>;
  min_price?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
};

export type WeaponPageInput = {
  category: Scalars['String']['input'];
  extra?: InputMaybe<ExtraWeaponPage>;
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  rarity?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Teams>;
};

export type Week = {
  __typename?: 'Week';
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
  week_end_date: Scalars['String']['output'];
  week_start_date: Scalars['String']['output'];
};

export type WeeklyChart = {
  __typename?: 'WeeklyChart';
  avg_price: Scalars['Int']['output'];
  max_price: Scalars['Int']['output'];
  min_price: Scalars['Int']['output'];
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
  week_end_date: Scalars['String']['output'];
  week_start_date: Scalars['String']['output'];
};
