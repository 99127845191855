<template>
  <div v-show="visible && content"
       ref="tooltipRef"
       :class="`tooltip-${pos}`"
       :style="tooltipStyle"
       class="tooltip"
  >
    <span class="tooltip-text" v-html="content"></span>
  </div>
</template>

<script lang="ts" setup>
import EventBus from '~/composables/bus';

const tooltipRef = ref<HTMLElement | null>(null);
const visible = ref(false);
const content = ref('');
const tooltipStyle = ref({});
const pos = ref<'top' | 'bottom'>('top')

EventBus.$on('show_tooltip', async ({text, x, y, position}) => {
  pos.value = position
  content.value = text;
  visible.value = true;
  await nextTick();
  const tooltipWidth = tooltipRef.value?.offsetWidth || 0; // Получаем ширину тултипа после его отрисовки
  const tooltipPositionY = position === 'top' ? y - (tooltipRef.value?.offsetHeight || 25) - 10 : y + 10; // Позиция сверху с отступом
  const tooltipPositionX = x - tooltipWidth / 2; // Рассчитываем X так, чтобы тултип был по центру
  tooltipStyle.value = {
    top: `${tooltipPositionY}px`,
    left: `${tooltipPositionX}px`,
    display: 'block'
  };
});
EventBus.$on('update_tooltip_text', async ({text}) => {
  content.value = text;
});

EventBus.$on('hide_tooltip', () => {
  visible.value = false;
});

</script>

<style lang="scss">
@use '/assets/scss/mixins' as *;

.tooltip {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  z-index: 99;
  box-shadow: 0 0 10px rgba(32, 34, 38, 0.25);
  background: linear-gradient(101.99deg, rgba(88, 226, 176, 0.8) -3.52%, rgba(62, 176, 211, 0.8) 89.43%);
  border-radius: 6px;
  padding: 5px;
  max-width: 300px;

  .tooltip-text {
    min-width: 120px;
    color: #000;
    font-weight: 600;
    text-align: center;
    //white-space: nowrap;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
    }
  }

  &.tooltip-top {
    .tooltip-text::after {
      top: calc(100% + 5px);
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(87, 229, 208, 0.7) transparent transparent transparent;
    }
  }

  &.tooltip-bottom {
    .tooltip-text::after {
      bottom: calc(100% + 5px);
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent rgba(87, 229, 208, 0.7) transparent;
    }
  }

  //&.tooltip-left {
  //  top: 50%;
  //  right: 125%;
  //  transform: translateY(-50%);
  //
  //  &::after {
  //    top: 50%;
  //    left: 100%;
  //    margin-top: -5px;
  //    border-width: 5px;
  //    border-style: solid;
  //    border-color: transparent transparent transparent rgba(62, 176, 211, 0.8);
  //  }
  //}
  //
  //&.tooltip-right {
  //  top: 50%;
  //  left: 125%;
  //  transform: translateY(-50%);
  //
  //  &::after {
  //    top: 50%;
  //    right: 100%;
  //    margin-top: -5px;
  //    border-width: 5px;
  //    border-style: solid;
  //    border-color: transparent rgba(88, 226, 176, 0.8) transparent transparent;
  //  }
  //}

  &.tooltip-text:hover {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .tooltip-text {
      white-space: unset;
      max-width: min-content;
      min-width: unset;
    }
  }
}
</style>
